import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import MyLogo from "../components/Logo"
import MyLogoFixed from "../components/LogoFixed"
import MySignature from "../images/Signature.svg"
import RSL from "../Audio/RSL.mp3"
import DAY1 from "../Audio/DAY1.mp3"


import "../Style/EssayPage.css"
import "../Style/100DaysChallengePage.css"
import TitleUnderlined from "../components/TitleUnderlined"

const EnglishCards = ({data}) => (
 <div> 

    <SEO 
    title="100 Days Challenge"
    />

    <Helmet>

        <meta name="robots" content="noindex" />
        <link rel="subresource" href="../Style/EssayPage.css" />

        <meta name="twitter:description" content="Phil Murphy">
        </meta>

        <meta name="og:description" content="Phil Murphy">
        </meta>

        <meta name="description" content="Phil Murphy">
        </meta>

        <meta name="image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image:alt" content="Phil Murphy">
        </meta>

        <meta name="thumbnail" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="og:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

    </Helmet>
    <div className="ChallengePageWrapper">
        <div className="DaysChallenge">

            <div className="ChallengePageHeader"><b>100</b> Days Challenge</div>

            <div className="ChallengeDescription">
                <b>Days:</b>
                <br />
                Successful: <b>1</b>
                <br />
                Skipped: <b>0</b>
                <br />
                Started: <b>Aug 31 2020; around 9 PM PST</b>
            </div>
                
            

                    <hr />

            <div>
                <div className="ChallengeTitle">Day 1</div>
                <div className="ChallengeDescription">I record a memo written by Jason Fried called <a href="https://m.signalvnoise.com/on-current-events/">"On current events"</a>. This memo contains thoughts about BLM protests and living a life without thinking about racism and why, in fact, you actually should.</div>
                <div className="ChallengeDescription"><b>Note:</b> It was fun experience. I'm glad to start my challenge with this important topic. Really like Jason's writing style.</div>
                <audio preload="auto" controls className="AudioPlayer">
                    <source src={DAY1} type="audio/mpeg" />
                    <p className="EssayAddContentPage">Your browser does not support the audio element :(</p>
                </audio>
            </div>

                        <hr />




        </div>
        </div>

    </div>
)

export default EnglishCards
